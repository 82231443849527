import {
    createRouter,
    createWebHashHistory,
   
} from 'vue-router'


const routes = [
    // 路由的默认路径
    {
        path: '/',
        redirect: "/home"
    },
    {
        path: '/home',
        component: () => import('../pages/Home.vue')
    },
    { 
        path: '/about_us',
        component: () => import('../pages/about_us.vue')
    }, 
    {
        path: '/content_us',
        component: () => import('../pages/content_us.vue')
    }, {
        path: '/officials',
        component: () => import('../pages/guanyuan.vue')
    },
    {
        path: '/cooperation',
        component: () => import('../pages/hezuo.vue')
    }, {
        path: '/news',
        component: () => import('../pages/news.vue')
    }, {
        path: '/company',
        component: () => import('../pages/yewu.vue')
    }
   
]

// 创建路由对象
const router = createRouter({
  
    history: createWebHashHistory(),
    routes,
    // 回到顶部
    scrollBehavior() {
        history.pushState(null, null, document.URL)
        return { left: 0, top: 0 }
    },
  
})
export default router;