import {createStore} from 'vuex'
export default createStore({
    state: {
        locale: "zh",  //语言 
    },
    mutations: {
        changeLocale(state,value){
            state.locale=value 
         }, 
    },
    actions: {
    },
    modules: {
    }
  })